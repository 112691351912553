<template>
    <Card>
        <template v-slot:header>
            <div class="px-3 pt-1">
                <div>
                    <Message v-if="images.length >= 4" severity="warn" :closable="false">You have reached maximun number of pictures!</Message>
                    <Message v-else severity="info" :closable="false">Minimun one picture is required. You Can add maximum four picture.</Message>
                </div>
                <div v-if="message">
                    <Message  :closable="false" severity="error">{{message}}</Message>
                </div>      
                <div v-if="sizeError">
                    <Message severity="error" :closable="false">{{sizeError}}</Message>
                </div>
            </div>
        </template>
        <template v-slot:content>
            <Toast />
            <Skeleton height="24rem" width="100%" v-if="pageLoading"/>
            <template v-else>
                <div class="grid">
                    <div class="col-12 md:col-6 lg:col-4" v-for="(image, myindex) of images" :key="myindex">
                        <Card>
                            <template #header>
                                <img :src="image.src"/>
                            </template>
                            <template #content>
                                <Button icon="pi pi-times" @click="removeImage(myindex)" label="Remove" class="p-button-secondary w-full"/>
                            </template>
                        </Card>
                    </div>
                    <div class="col-12 md:col-4 lg:col-3">                                           
                        <div class="uppercase">
                            <div class="flex align-content-center justify-content-center flex-wrap" style="min-height: 200px">
                                <div class="flex align-items-center justify-content-center w-8">
                                    <!-- <Button label="Add new photo" icon="pi pi-image" class="p-button p-button-raised w-full mt-auto py-3" @click="(changePhotoDialog = true),(imgIndex(images.length, images.length+1))" :disabled="images.length >= 4" /> -->
                                    <Button label="Add new photo" icon="pi pi-image" class="p-button p-button-raised w-full mt-auto py-3" @click="changePhotoDialog = true" :disabled="images.length >= 4" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <Dialog header="Add new photo" v-model:visible="changePhotoDialog" :breakpoints="{'768px': '90vw'}" :style="{width: '40vw'}" :modal="true" @hide="closePhotoDialog">
                <BlockUI :blocked="profilePhotoLoading">
                    <div class="flex justify-content-center">
                        <Cropper
                            class="flex w-full"
                            :src="photoToCrop"
                            :stencil-component="$options.components.CircleStencil"
                            :stencil-props="{
                                aspectRatio: 1.91/1
                            }"
                            @change="onPhotoChange"
                            v-if="photoToCrop"
                        />
                    </div>
                </BlockUI>
                <template #footer>
                    <div class="flex justify-content-center mb-4">
                        <template v-if="photoToCrop">
                            <Button class="flex p-button-secondary p-button-raised" label="Discard" icon="pi pi-times" @click="discardPhoto" :disabled="profilePhotoLoading" />
                            <Button class="flex p-button-raised" label="Save New" icon="pi pi-check" @click="cropPhoto" :disabled="profilePhotoLoading" />
                        </template>
                        <template v-else>
                            <FileUpload class="flex p-button-raised" chooseLabel="Select new photo" mode="basic" v-model="selectedPhoto" accept="image/*" :maxFileSize="8000000" @select="onPhotoSelect" />
                        </template>
                    </div>
                </template>
            </Dialog>
        </template>
        <template v-slot:footer>
            <div class="flex justify-content-between flex-wrap mb-4">
                <div class="flex align-items-center justify-content-center">
                    <Button label="Back" @click="prevPage()" icon="pi pi-angle-left" style="width:200px"></Button>
                </div>
                <div class="flex align-items-center justify-content-center">
                    <Button label="Next" @click="nextPage(images)" icon="pi pi-angle-right" icon-pos="right" style="width:200px"></Button>
                </div>
            </div>
        </template>
    </Card>
</template>

<script>
import { ref } from "vue";
import { useToast } from 'primevue/usetoast';
import {  Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
    components: {
		Cropper
	},
    setup: () => {
        const toast = useToast();

        const pageLoading = ref(false);
        const profilePhotoLoading = ref(false);
        const changePhotoDialog = ref(false);

        const photo = ref(null);
        const images = ref([]);
        const selectedPhoto = ref(null);
        const photoToCrop = ref(null);

        const first_image = ref();
        const second_image = ref();
        const third_image = ref();
        const fourth_image = ref();

        const message = ref(null);
        const sizeError = ref(null);
        const imageSizeTotal = ref(0);

        const onPhotoSelect = (event) => {
            photoToCrop.value = event.files[0].objectURL;
            changePhotoDialog.value = true;
            message.value = null;
        };

        const onPhotoChange = ({  canvas }) => {
            photo.value = canvas;
        };

        const discardPhoto = () => {
            photo.value = null;
            selectedPhoto.value = null;
            photoToCrop.value = null;
            changePhotoDialog.value = false;
        };

        const cropPhoto = () => {
            profilePhotoLoading.value = true;

            photo.value.toBlob(blob => {
                imageSizeTotal.value = imageSizeTotal.value + blob.size;
                images.value.push(blob);
                images.value[images.value.length-1].src = URL.createObjectURL(blob);

                if (imageSizeTotal.value > 5*1048576 && imageSizeTotal.value <= 8*1048576) {
                    sizeError.value = 'Your picture(s) size is big ('+ (imageSizeTotal.value/1048576).toFixed(2) +'MB). It will take a bit more time to upload';
                } else if (imageSizeTotal.value > 8*1048576 && imageSizeTotal.value <= 16*1048576) {
                    sizeError.value = 'Your picture(s) size is large ('+ (imageSizeTotal.value/1048576).toFixed(2) +'MB). It will take a bit long time to upload';
                } else if (imageSizeTotal.value > 16*1048576 && imageSizeTotal.value <= 24*1048576) {
                    sizeError.value = 'Your picture(s) size is very large ('+ (imageSizeTotal.value/1048576).toFixed(2) +'MB). It will take long time to upload';
                } else if (imageSizeTotal.value > 24*1048576){
                    sizeError.value = 'Your picture(s) size is huge ('+ (imageSizeTotal.value/1048576).toFixed(2) +'MB). It will take few munites to upload';
                } else {
                    sizeError.value = '';
                }
            }, 'image/jpeg');

            changePhotoDialog.value = false;
            profilePhotoLoading.value = false;
            discardPhoto();
        };

        const closePhotoDialog = () => {
            changePhotoDialog.value = false;
            discardPhoto();
        };

        const removeImage = (index) =>{
            imageSizeTotal.value = imageSizeTotal.value - images.value[index].size;
            images.value.splice(index, 1);

            if (imageSizeTotal.value > 5*1048576 && imageSizeTotal.value <= 8*1048576) {
                sizeError.value = 'Your picture(s) size is big ('+ (imageSizeTotal.value/1048576).toFixed(2) +'MB). It will take a bit more time to upload.';
            } else if (imageSizeTotal.value > 8*1048576 && imageSizeTotal.value <= 16*1048576) {
                sizeError.value = 'Your picture(s) size is large ('+ (imageSizeTotal.value/1048576).toFixed(2) +'MB). It will take a bit long time to upload.';
            } else if (imageSizeTotal.value > 16*1048576 && imageSizeTotal.value <= 24*1048576) {
                sizeError.value = 'Your picture(s) size is very large ('+ (imageSizeTotal.value/1048576).toFixed(2) +'MB). It will take long time to upload.';
            } else if (imageSizeTotal.value > 24*1048576 && imageSizeTotal.value <= 32*1048576){
                sizeError.value = 'Your picture(s) size is huge ('+ (imageSizeTotal.value/1048576).toFixed(2) +'MB). It will take few munites to upload.';
            } else {
                sizeError.value = '';
            }
        };

        const showToast = (severity, summary, detail) => {
            toast.add({severity: severity, summary: summary, detail: detail, life: 5000});
        }

        return {
            pageLoading,
            profilePhotoLoading,

            changePhotoDialog,

            photo,
            selectedPhoto,
            photoToCrop,
            images,

            first_image,
            second_image,
            third_image,
            fourth_image,
            message,

            onPhotoSelect,
            onPhotoChange,
            discardPhoto,
            cropPhoto,
            closePhotoDialog,
            removeImage,
            sizeError,
            imageSizeTotal,
            showToast,
        }
    },
    methods: {
        nextPage(images) {
            for(var i = 0; i<images.length; i++){
                if(i == 0){
                    this.first_image = images[0];
                }
                if(i == 1){
                    this.second_image = images[1];
                }
                if(i == 2){
                    this.third_image = images[2];
                }
                if(i == 3){
                    this.fourth_image = images[3];
                }
            }
            if(images.length > 0){
                this.$emit('next-page', {formData: {
                    first_image: this.first_image,
                    second_image: this.second_image,
                    third_image: this.third_image,
                    fourth_image: this.fourth_image,
                }, pageIndex: 3});
            }else{
                this.message = 'First picture is required'
            }
        },
        prevPage() {
            this.$emit('prev-page', {pageIndex: 3});
        }
    }
}
</script>

<style lang="scss" scoped>

</style>